export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/png","href":"/favicon.png"},{"rel":"preconnect","href":"https://cdn.sanity.io"},{"rel":"preconnect","href":"https://d3b5ou77.api.sanity.io"}],"style":[],"script":[{"type":"application/ld-json","children":"{\"@context\":\"https://schema.org\",\"@type\":\"Organization\",\"image\":\"https://cdn.sanity.io/images/d3b5ou77/production/9ed1da60d7dbf6ffedc586962d32527b6e6bf73c-431x382.png\",\"url\":\"https://projectopal.co\",\"sameAs\":[\"https://projectopal.co\",\"https://projectopal.co/team\",\"https://projectopal.co/services\",\"https://projectopal.co/news\"],\"logo\":\"https://cdn.sanity.io/images/d3b5ou77/production/9ed1da60d7dbf6ffedc586962d32527b6e6bf73c-431x382.png\",\"name\":\"Project Opal\",\"description\":\"\",\"email\":\"contact@projectopal.co\",\"telephone\":\"0330 043 3846\",\"address\":{\"@type\":\"PostalAddress\",\"streetAddress\":\"Weir Mill\",\"addressLocality\":\"Mossley\",\"addressCountry\":\"UK\",\"addressRegion\":\"Greater Manchester\",\"postalCode\":\"OL5 9QA\"},\"vatID\":\"\"}"},{"type":"text/javascript","src":"https://js-eu1.hs-scripts.com/144557950.js","id":"hs-script-loader","async":true,"defer":true},{"children":"\n          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n          })(window,document,'script','dataLayer','GTM-P73LLZ2T');\n          "}],"noscript":[{"innerHTML":"\n          <iframe src=\"https://www.googletagmanager.com/ns.html?id=GTM-P73LLZ2T\"\n          height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"></iframe>"}],"htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = false

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false