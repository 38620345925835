<template>
  <div>
    <Navbar />
    <NuxtPage />
    <Footer />
    <CookieBanner />
    <CSSEditorPanel v-if="$config.public.devMode && isCreativeMode" />
    <ThemePicker v-if="$config.public.devMode && isCreativeMode" />
  </div>
</template>
<script setup lang="ts">
const route = useRoute()
const isCreativeMode = route.query.creative === 'true'
</script>
<style>
html, body {
  margin: 0;
  padding: 0;
}
</style>
