import chroma from 'chroma-js'

export interface RGBObj {
  r: number;
  g: number;
  b: number;
  a: number;
}
export interface ColorPicker {
  label: string;
  value: string;
}
export function rgbStringFromObj (rgbObj: RGBObj) {
  if (!rgbObj) { return }
  const { r, g, b } = rgbObj
  if (!r || !g || !b) { return }
  const a = rgbObj.a ?? 1

  return `rgb(${rgbObj.r} ${rgbObj.g} ${rgbObj.b} / ${a * 100}%)`
}

export const getColor = (color: string) => chroma(color)

export const getPalette = (color: string) => {
  const colors = chroma.scale([color, '#150A29'])
  const palette = []

  // Create 50
  palette.push(colors(0.05).rgb().toString().replaceAll(',', ' '))

  // Create 100-900
  for (let i = 0.1; i < 0.9; i += 0.1) {
    palette.push(colors(i).rgb().toString().replaceAll(',', ' '))
  }
  return palette
}

export const isValid = (color: string) => chroma.valid(color)

export function setColorVars (color: string, name = 'main') {

}

export function getCssVars () {
  return document.querySelector('body')?.getAttribute('style')?.replaceAll('; ', ';\n')
}

export const getCodeString = (color: string, name = 'myColor') => {
  const palette = getPalette(color)
  const json = {
    theme: {
      extend: {
        colors: {
          [name]: {
            50: palette[0],
            100: palette[1],
            200: palette[2],
            300: palette[3],
            400: palette[4],
            500: palette[5],
            600: palette[6],
            700: palette[7],
            800: palette[8],
            900: palette[9]
          }
        }
      }
    }
  }
  return `module.exports = ${JSON.stringify(json, null, 2)}`
}

export function hexToRgb (hex:string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? `${parseInt(result[1], 16)} ${parseInt(result[2], 16)} ${parseInt(result[3], 16)}` : null
}

function getRGB (c:string):number {
  return parseInt(c, 16)
}

export function getsRGB (c:string) {
  return getRGB(c) / 255 <= 0.03928
    ? getRGB(c) / 255 / 12.92
    : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4)
}

function componentToHex (c: number) {
  const hex = c.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}
export function rgbToHex (r: number, g: number, b: number) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
}

function getContrastYIQ (hexColor:string) {
  const r = parseInt(hexColor.substring(1, 3), 16)
  const g = parseInt(hexColor.substring(3, 5), 16)
  const b = parseInt(hexColor.substring(5, 7), 16)
  return ((r * 299) + (g * 587) + (b * 114)) / 1000
}

export function getTextColor (bgColor: string) {
  const rgbColor = bgColor.replaceAll('rgb(', '').replaceAll(')', '').split(' ')
  const r = parseInt(rgbColor[0], 10)
  const g = parseInt(rgbColor[1], 10)
  const b = parseInt(rgbColor[2], 10)

  const color = bgColor.includes('#') ? bgColor : rgbToHex(r, g, b)

  const contrast = getContrastYIQ(color)
  return contrast >= 192 ? '#000000' : '#ffffff'
}
