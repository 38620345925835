import { default as _91slug_93T5ITLsEbU0Meta } from "/usr/app/pages/[slug].vue?macro=true";
import { default as indexfzSMqasWUtMeta } from "/usr/app/pages/index.vue?macro=true";
import { default as _91slug_93Bazmm0hfKxMeta } from "/usr/app/pages/news/[slug].vue?macro=true";
import { default as _91slug_93vQRQMIRkAfMeta } from "/usr/app/pages/team/[slug].vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/usr/app/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/usr/app/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "team-slug",
    path: "/team/:slug()",
    component: () => import("/usr/app/pages/team/[slug].vue").then(m => m.default || m)
  }
]